<template>
  <div class="data-overview-card">
    <div class="left-icon" v-if="!newData.small">
      <img src="../../../assets/square_diagonal.png" alt="left-icon" />
    </div>
    <div class="data-overview-source">
      <div class="data-source-icon" v-if="!newData.small">
        <img :src="newData.icon" alt="data-source-icon" />
      </div>
      <slot>
        <div class="data-source-content">
          <p class="source-card-title">
            {{ newData.title
            }}<span v-if="newData.classTitle">({{ newData.classTitle }})</span>
          </p>
          <p class="source-card-content">
            <a-tooltip :title="content" placement="rightTop">
              {{ newData.content }}
              <span class="source-title-type">{{ type }}</span>
            </a-tooltip>
          </p>
        </div>
      </slot>
    </div>
    <div
      class="data-source-navBar"
      v-if="newData.navList && newData.navList.length > 0"
    >
      <div class="mask"></div>
      <div class="data-navBar-content">
        <div
          v-for="(item, index) in newData.navList"
          :key="index"
          :class="{ active: newData.active === item.id }"
          @click="onActive(item)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  // data() {},
  props: {
    dataSource: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      type: ''
    };
  },
  methods: {
    onActive(item) {
      this.$emit('onActive', item, this.dataSource);
    },
    getContent(content) {
      let result = 0;
      if (content && content.length > 5) {
        result = parseInt(content);
      } else {
        result = content;
      }
      if (result && result.toString().length > 5) {
        result = (result / 10000).toFixed(2);
        if (result < 1) {
          result = content.toFixed(2);
          return result;
        }
        this.type = '(万)';
      }
      if (result && result.toString().length > 4 && this.type) {
        result = (result / 1).toFixed(1);
        if ((result * 10) % 10 === 0) {
          result = (result / 1).toFixed(0);
        } else {
          result = (result / 1).toFixed(1);
        }
      }
      if (result && result.toString().length > 4 && this.type) {
        result = (result / 1).toFixed(0);
      }
      if (!this.type) {
        if (result % 1 === 0) {
          result = (result / 1).toFixed(0);
        } else if ((result * 10) % 1 === 0) {
          result = (result / 1).toFixed(1);
        } else if ((result * 10) % 1 === 0) {
          result = (result / 1).toFixed(1);
        } else {
          result = (result / 1).toFixed(2);
        }
      }
      return result;
    }
  },
  computed: {
    newData() {
      let data = {
        ...this.dataSource
      };
      if (data) {
        data.content = this.getContent(data.content);
      }
      return data;
    },
    content() {
      return this.dataSource.content;
    }
  }
};
</script>

<style lang="less" scoped>
.data-overview-card {
  position: relative;
  border-radius: 4px;
  .left-icon {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 16px;
    height: 16px;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .data-overview-source {
    height: 100%;
    .data-source-icon {
      width: 47px;
      height: 46px;
      border-radius: 8px;
      opacity: 0.58;
      position: relative;
      display: inline-block;

      img {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .data-source-content {
      display: inline-block;
      margin-left: 10px;
      width: 105px;
      .source-card-title {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        margin: 0;
        padding: 0;
      }
      .source-card-content {
        font-size: 28px;
        font-family: DINCondensed-Bold, DINCondensed;
        font-weight: bold;
        color: #ffffff;
        white-space: nowrap;
        //line-height: 34px;
        //margin-top: 8px;
        //  overflow: hidden;
      }
    }
  }
  .data-source-navBar {
    width: 16px;
    border-radius: 8px;
    position: absolute;
    bottom: 4px;
    right: 4px;
    overflow: hidden;
    color: #ffffff;
    .mask {
      background: #e2e2f3;
      opacity: 0.35;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      z-index: 1;
    }
    .data-navBar-content {
      z-index: 2;
      position: relative;
      text-align: center;
      margin: 0 auto;
      div {
        min-height: 14px;
        font-weight: 500;
        line-height: 14px;
        text-align: center;
        margin: 0 auto;
        margin: 3px 1px;
        font-size: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        text-align: center;
        cursor: pointer;
      }
    }

    .active {
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
.source-title-type {
  font-size: 1px;
}
</style>
