<template>
  <div class="top-class-list">
    <TopCard
      v-for="(item, index) in dataSource"
      :key="index"
      :data-source="item"
      :class="item.class"
      @onActive="onActive"
      class="top-class-card"
    ></TopCard>
  </div>
</template>

<script>
import TopCard from './TopCard.vue';
import { getBasicStatisticsIndex } from '../../../api/index';
// '@views/dataCenter/api/DataOverview.js';

const userCheckedIcon = require('../../../assets/user_checked.png');
const userEffectiveIcon = require('../../../assets/user_effective.png');
const pieQuarterIcon = require('../../../assets/pie_quarter.png');
const clockIcon = require('../../../assets/clock.png');

export default {
  data() {
    const dataSource = [
      {
        id: 1,
        title: '累计注册人数',
        content: '0',
        icon: userCheckedIcon,
        classTitle: '',
        class: 'user-checked-background'
      },
      {
        id: 2,
        title: '当前有效人数',
        content: '0',
        icon: userEffectiveIcon,
        classTitle: '',
        class: 'user-effective-background'
      },
      {
        id: 3,
        title: '累计学习时长',
        content: '0',
        icon: pieQuarterIcon,
        classTitle: '分',
        class: 'pie-quarter-background',
        navList: [
          {
            id: 1,
            title: '分',
            content: ''
          },
          {
            id: 2,
            title: '时',
            content: ''
          },
          {
            id: 3,
            title: '人天',
            content: ''
          }
        ],
        active: 1
      },
      {
        id: 4,
        title: '人均学习时长',
        content: '0',
        icon: clockIcon,
        classTitle: '分',
        class: 'clock-background',
        navList: [
          {
            id: 1,
            title: '分',
            content: ''
          },
          {
            id: 2,
            title: '时',
            content: ''
          },
          {
            id: 3,
            title: '天',
            content: ''
          }
        ],
        active: 1
      }
    ];

    return {
      dataSource
    };
  },
  components: {
    TopCard
  },
  methods: {
    onActive(itemChildren, item) {
      item.active = itemChildren.id;
      item.classTitle = itemChildren.title;
      item.content = itemChildren.content;
    },
    async getBasicStatisticsIndex() {
      const data = await getBasicStatisticsIndex();
      if (!(data && data.error_code === 0)) {
        return;
      }
      this.dataSource[0].content = data.data.register_num_all;
      this.dataSource[1].content = data.data.effective_num_all;
      this.dataSource[2].content = data.data.study_time_minutes;
      this.dataSource[3].content = data.data.average_study_time_minutes;
      this.dataSource[2].navList[0].content = data.data.study_time_minutes;
      this.dataSource[3].navList[0].content =
        data.data.average_study_time_minutes;
      this.dataSource[2].navList[1].content = data.data.study_time_hour;
      this.dataSource[3].navList[1].content = data.data.average_study_time_hour;
      this.dataSource[2].navList[2].content = data.data.study_time_day;
      this.dataSource[3].navList[2].content = data.data.average_study_time_day;
      return data.data;
    }
  },
  created() {
    this.getBasicStatisticsIndex();
  }
};
</script>

<style lang="less" scoped>
.top-class-list {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  & > * {
    margin-top: 0 !important;
  }
  .top-class-card {
    width: 217px;
    height: 92px;
    margin: 20px 25px 0 0;
    ::v-deep .data-overview-source {
      width: 215px;
      padding: 21px 5px 0 23px;
      display: flex;
    }
  }
  .user-checked-background {
    background: #334fa0;
    ::v-deep .data-source-icon {
      background: #284085;
    }
  }
  .user-effective-background {
    background: #3c85b8;
    ::v-deep .data-source-icon {
      background: #1f6190;
    }
  }
  .pie-quarter-background {
    background: #95dac1;
    ::v-deep .data-source-icon {
      background: #79b7a1;
    }
    ::v-deep .data-source-icon {
      color: #6d9b8b;
    }
    ::v-deep .active {
      background: #6d9b8b;
      color: #edf0fc;
    }
  }
  .clock-background {
    background: #f0c944;
    ::v-deep .data-source-icon {
      background: #d8b744;
    }
    ::v-deep .data-source-icon {
      color: #c9a320;
    }
    ::v-deep .active {
      background: #d4ab20;
      color: #edf0fc;
    }
  }
}
</style>
