<template>
  <Card :show-title-bar="false" class="nav">
    <div
      v-for="item in list"
      :key="item.id"
      class="nav-item"
      :class="{ active: item.id === activeId }"
      @click="selectItem(item)"
    >
      <div class="flex flex-start pointer-cursor nav-item-inner">
        <img
          v-if="item.id === activeId"
          :src="item.activeIcon"
          alt=""
          class="mr-15 icon"
        />
        <img v-else :src="item.icon" alt="" class="mr-15 icon" />
        <span>{{ item.name }}</span>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from './Card.vue';

const dataOverviewIcon = require('../../assets/nav/data_overview.png');
const dataOverviewActiveIcon = require('../../assets/nav/data_overview_active.png');

const participationIcon = require('../../assets/nav/participation_analysis.png');
const participationActiveIcon = require('../../assets/nav/participation_analysis_active.png');

const peopleImportIcon = require('../../assets/nav/people_import.png');
const peopleImportActiveIcon = require('../../assets/nav/people_import_active.png');

export default {
  name: 'Nav',
  components: {
    Card
  },
  model: {
    prop: 'value',
    event: 'select'
  },
  props: {
    value: { type: String, default: 'work' }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.list.map((i) => i.id).includes(val)) {
          this.activeId = val;
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      activeId: '',
      list: [
        {
          id: 'dataOverview',
          path: 'dataOverview',
          name: '数据概览',
          icon: dataOverviewIcon,
          activeIcon: dataOverviewActiveIcon
        },
        {
          id: 'participationAnalysis',
          path: 'participationAnalysis',
          name: '参训率分析',
          icon: participationIcon,
          activeIcon: participationActiveIcon
        },
        {
          id: 'peopleImport',
          path: 'people-import',
          name: '人员导入',
          icon: peopleImportIcon,
          activeIcon: peopleImportActiveIcon
        }
      ]
    };
  },
  created() {
    const path = this.$route.path.split('/').pop();
    this.activeId = this.list.filter((i) => i.path === path)?.[0]?.id || '';
  },
  methods: {
    selectItem(item) {
      if (this.loading || this.activeId === item.id) {
        return;
      }
      this.loading = true;

      this.activeId = item.id;
      this.$router
        .push({
          path: `/index/dataCenter/${item.path}`
        })
        .then(() => {
          this.$emit('select', item.id);
          this.$emit('selected', { id: item.id });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.nav {
  overflow-x: hidden;
  overflow-y: auto;
  width: 244px;
  min-height: 60px;
  margin-bottom: 10px;
  padding: 0;
  font-family: PingFang SC-Bold, PingFang SC, sans-serif;

  .nav-item {
    width: 100%;
    height: 58px;
    padding: 3px 11px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    background-color: #ffffff;

    .nav-item-inner {
      width: 100%;
      height: 100%;
      padding-left: 27px;
      border-radius: 29px;
      box-shadow: 0 0 0 0 rgba(19, 37, 85, 0.42);
      cursor: pointer;
    }

    &.active .nav-item-inner {
      color: #ffffff;
      background: linear-gradient(90deg, #334fa0 0%, #3c85b8 100%);
      box-shadow: 0 0 0 0 rgba(19, 37, 85, 0.42);
    }

    .icon {
      width: 15px;
      height: 15px;
    }
  }
}
</style>
