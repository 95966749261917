<template>
  <Card class="comparison-analysis">
    <span slot="title">参训率、完训率、考试通过率对比解析</span>
    <div slot="extra">
      <ButtonDownload
        @click="downloadImage('参训率、完训率、考试通过率对比解析')"
      />
      <ButtonExportData @click="exportExcel" :loading="loading" />
    </div>

    <div class="query-1 main-selected-color-wrapper">
      <SelectTypedDate v-model="typedDate"></SelectTypedDate>
      <SelectCourse v-model="courseIds" />
      <SelectShopType v-model="orgParams" :not-all="true" :same-level="true" />
      <SelectMultiple
        v-model="comparisonIds"
        :options="comparisonItems"
        placeholder="请选择对比项"
      />
    </div>

    <div class="query-2">
      <ButtonSmall type="primary" @click="onSearch" :loading="loading"
        >查询</ButtonSmall
      >
      <ButtonSmall @click="onReset" :loading="loading">重置</ButtonSmall>
      <ButtonSmall @click="showHiddenModal">可隐藏项</ButtonSmall>
      <!-- <ButtonSmall v-if="!!level" @click="levelBack">返回上级</ButtonSmall> -->
    </div>
    <div class="btn-back" v-if="!!level" @click="levelBack">
      <span><img src="../../../assets/buttons/data-center-back.png" alt="" /> </span>
      <span class="btn-back-text"> 返回 </span>
    </div>
    <MultipleBarChart
      v-if="originData && this.absData.length > 0"
      ref="chartRef"
      :chart-data="chartData"
      class="chart"
      @x-axis-click="levelForward"
    />
    <template v-else>
      <a-empty />
    </template>
    <CheckboxModal
      ref="checkboxModalRef"
      :value="visibleIds"
      title="可隐藏项"
      :options="allVisibleItems"
      @save="visibleSave"
    />
  </Card>
</template>

<script>
import moment from "moment";

import ToggleChartVisible from "../../../mixin/ToggleChartVisible";

import Card from "../../layout/Card.vue";
import ButtonDownload from "../../btn/ButtonDownload.vue";
import ButtonExportData from "../../btn/ButtonExportData.vue";
import ButtonSmall from "../../btn/ButtonSmall.vue";
import SelectCourse from "../../selection/SelectCourse.vue";
import SelectMultiple from "../../selection/SelectMultiple.vue";
import SelectTypedDate from "../../selection/SelectTypedDate";
import MultipleBarChart from "../../charts/MultipleBarChart.vue";
import { getRatioParsing, excelRatioParsing } from "../../../api/index";
import DateCommon from "@/utils/DateCommon";
import SelectShopType from "../../selection/SelectShopType";

const comparisonItems = [
  { id: 3, name: "参训率" },
  { id: 1, name: "完训率" },
  { id: 2, name: "考试通过率" },
];

export default {
  name: "ComparisonAnalysis",
  components: {
    Card,
    ButtonDownload,
    ButtonExportData,
    ButtonSmall,
    SelectCourse,
    SelectShopType,
    SelectMultiple,
    SelectTypedDate,
    MultipleBarChart,
  },
  mixins: [ToggleChartVisible],
  watch: {
    comparisonIds(val) {
      if (!val) {
        this.comparisonIds = `${this.comparisonItems?.[2]?.id}`;
      }
    },
  },
  data() {
    return {
      typedDate: {
        type: "month",
        date: moment().subtract(1, "months").format("YYYY-MM"),
      },
      courseIds: "",
      shopIds: [],
      loading: false,
      comparisonIds: comparisonItems.map((i) => i.id).join(",") || [],
      level: 0,
      comparisonItems,
      params: {},
      originData: {},
      orgIds: [],
      orgId: "",
      absData: [""],
      orgParams: {},
    };
  },
  mounted() {
    this.onReset();
  },
  methods: {
    async getRatioParsing(params) {
      this.$refs?.chartRef?.showLoading();
      this.loading = true;
      const data = await getRatioParsing(params).finally(() => {
        this.$refs?.chartRef?.hideLoading();
        this.loading = false;
      });
      if (!(data && data.error_code === 0)) {
        this.originData = undefined;
        this.$refs?.chartRef?.hideLoading();
        return;
      }
      let participation_rate_datas = [];
      let after_training_rate_datas = [];
      let pass_rate_datas = [];
      this.absData = data.data;
      data.data.forEach((s) => {
        participation_rate_datas.push(s.participation_rate_data);
        after_training_rate_datas.push(s.after_training_rate_data);
        pass_rate_datas.push(s.pass_rate_data);
      });
      this.absData = data.data;
      const comparisonIds = this.comparisonIds.split(",");
      const series = [
        {
          name: "参训率",
          type: "bar",
          data: participation_rate_datas,
          selected: !!comparisonIds.find((s) => s === "3"),
        },
        {
          name: "完训率",
          type: "bar",
          stack: "Total",
          data: after_training_rate_datas,
          selected: !!comparisonIds.find((s) => s === "1"),
        },
        {
          name: "考试通过率",
          type: "bar",
          stack: "south",
          data: pass_rate_datas,
          selected: !!comparisonIds.find((s) => s === "2"),
        },
      ].filter((s) => s?.selected);

      this.originData = {
        date: data.meta.abscissa,
        series,
        color: [
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#324FA1", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#6187CF", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#95DAC1", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#C7EFE2", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
          {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: "#FFDB55", // 0% 处的颜色
              },
              {
                offset: 1,
                color: "#FFECA1", // 100% 处的颜色
              },
            ],
            global: false, // 缺省为 false
          },
        ],
        baseLine: data.meta.participation_rate_average,
        title: "参训率、完训率、考试通过率对比解析",
        yAxisName: "",
        showLegend: true,
        yAxisLabelFormatter: "{value}%",
        grid: {
          bottom: 150,
          right: 55,
        },
        zoomNum: 5,
      };
    },
    onSearch() {
      this.getData();
    },
    onReset() {
      this.typedDate = {
        type: "month",
        date: moment().subtract(1, "months").format("YYYY-MM"),
      };

      this.courseIds = "";
      this.shopIds = [];
      this.comparisonIds = comparisonItems.map((i) => i.id).join(",") || [];
      this.getData();
    },
    async getData() {
      const params = this.getParams();
      if (!params) {
        return;
      }
      this.absData = [""];
      setTimeout(async () => {
        await this.getRatioParsing(params);
        this.visibleIds = this.allVisibleItems
          .map((i, idx) => idx + 1)
          .join(",");
      });
    },
    levelForward(evt) {
      if (this.level >= 5) {
        return;
      }
      const data = this.absData.find(
        (s) => s.name.search(evt.value.substr(0, 4)) >= 0
      );
      if (data.id) {
        this.orgId = data.id;
        this.orgIds.push(data.id);
        this.onSearch();
      }
      this.level += 1;
    },
    levelBack() {
      this.orgIds = this.orgIds.filter((s) => s !== this.orgId);
      if (!this.level) {
        return;
      }
      this.orgId = this.orgIds[this.orgIds.length - 1];
      this.level -= 1;
      this.onSearch();
    },
    exportExcel() {
      this.loading = true;
      excelRatioParsing({
        ...this.getParams(),
        is_download: 1,
      }).finally(() => {
        this.loading = false;
      });
    },
    getParams() {
      if (!this.typedDate.date) {
        this.$message.error("请选择时间");
        return;
      }
      return {
        course_ids: this.courseIds,
        day_type: this.typedDate.type,
        org_id: this.orgId,
        start_day: DateCommon.getMomentStart(
          this.typedDate.type,
          this.typedDate.date
        ),
        participation_type: this.comparisonIds,
        ...this.orgParams,
      };
    },
    onblur(data) {
      this.shopIds = data;
    },
  },
};
</script>

<style scoped lang="scss">
.comparison-analysis {
}

.query-1 {
  margin-bottom: 10px;
}

.query-2 {
  margin-bottom: 5px;
}

.query-1,
.query-2 {
  & > * {
    margin-left: 0;
    margin-right: 10px;
    vertical-align: middle;

    &:last-child {
      margin-right: 0;
    }
  }
}

::v-deep .ant-empty {
  margin: 5% 0;
}

.btn-back {
  width: 80px;
  display: inline-block;
  height: 20px;
  cursor: pointer;
  z-index: 999;
  position: relative;
  left: 40px;
  top: 25px;
  .btn-back-text {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3350a2;
  }
}
</style>
