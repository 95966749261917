<template>
  <div class="people-import">
    <a-form-model
      label-align="right"
      ref="formRef"
      :model="form"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 12 }"
    >
      <a-form-model-item label="名单名称" prop="title">
        <a-input
          v-model="form.title"
          :disabled="uploading"
          placeholder="名单名称（不可重复）"
        ></a-input>
      </a-form-model-item>
      <!-- <a-form-model-item label="上传人员名单" prop="fileList">
        <a-upload
          :disabled="uploading"
          name="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :file-list="form.fileList"
          :multiple="false"
          :before-upload="beforeUpload"
          :custom-request="handleRequestUpload"
        >
          <a-button>上传</a-button>
        </a-upload>
      </a-form-model-item> -->
      <a-form-model-item prop="day">
        <template slot="label">
          <a-select
            v-model="optionType"
            style="width: 80px"
            @select="onSelect"
            :options="options"
          >
          </a-select>
        </template>
        <DatePicker
          v-model="week"
          :type="'week'"
          v-if="optionType === 'week'"
          range
          :week-start="1"
          :mark-function="markFunction"
          @change="onWeekChange"
          :max="max"
        ></DatePicker>
        <DatePicker
          v-model="month"
          :type="'month'"
          :mark-function="markFunction"
          v-if="optionType === 'month'"
          @change="onMonthChange"
          :max="max"
        ></DatePicker>
      </a-form-model-item>
      <a-form-model-item prop="fileList">
        <template slot="label">人员名单</template>
        <div>
          <span>
            <input
              v-if="form.title && form.fileList.length > 0"
              :disabled="true"
              placeholder="请选择文件"
              class="input-file"
              v-model="form.title"
            />
          </span>
          <a-upload
            :disabled="uploading"
            name="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :file-list="dataFileList"
            :multiple="false"
            :before-upload="beforeUpload"
            :custom-request="handleRequestUpload"
          >
            <a-button type="primary" :disabled="form.fileList.length > 0"
              >上传</a-button
            >
          </a-upload>
        </div>
        <div v-if="form.fileList.length > 0">
          <a-button
            type="primary"
            @click="downloadFile()"
            :loading="downloading"
            :disabled="!form.excel_url"
            >下载</a-button
          >
          <a-button @click="deleteFile()">删除</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item>
        <span>点击</span>
        <a :href="templateUrl" target="_blank">此处</a>
        <span>下载名单</span>
      </a-form-model-item>
      <a-form-model-item>
        <a-button
          type="primary"
          @click="save()"
          :disabled="!isupLoadFile"
          :loading="loading"
        >
          保存
        </a-button>
        <a-button @click="cacel()"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-modal v-model="visible" title="错误信息" @ok="onClose" @cancel="onClose">
      <a-table :columns="columns" :data-source="data" rowKey="id" :key="id">
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { deleteDelnew, getScreennew, userimportFile } from './api/index';
import moment from 'moment';
import { downloadFileExtra } from '@/utils/JsDownload';
import DatePicker from '@hyjiacan/vue-datepicker';
import '@hyjiacan/vue-datepicker/dist/datepicker.css';

export default {
  name: 'PeopleImport',
  computed: {
    ...mapState({
      templateUrl: (state) => state.StatisticsParticipation.peopleTemplateUrl,
      peopleList: (state) => state.StatisticsParticipation.peopleList
    })
  },
  components: {
    DatePicker
  },
  data() {
    const titleValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error(rule?.message || '请填写内容'));
      } else if (
        this.peopleList.findIndex((i) => i.title === this.form.title) > -1
      ) {
        callback(new Error(rule?.message || '该名称已存在'));
      } else {
        callback();
      }
    };
    const fileListValidator = (rule, value, callback) => {
      if (!this.form.fileList?.length) {
        callback(new Error(rule?.message || '请选择文件'));
      } else {
        callback();
      }
    };

    const dayValidator = (rule, value, callback) => {
      if (!this.form.day) {
        callback(new Error(rule?.message || '请选择日期'));
      } else {
        callback();
      }
    };

    const options = [
      {
        label: '周',
        value: 'week'
      },
      {
        label: '月',
        value: 'month'
      }
    ];
    const columns = [
      {
        title: '身份证号',
        dataIndex: 'idcard',
        key: 'idcard',
        slots: { title: 'idcard' },
        scopedSlots: { customRender: 'name' },
        align: 'center'
      },
      {
        title: '错误信息',
        dataIndex: 'error',
        key: 'error',
        scopedSlots: { customRender: 'error' },
        align: 'center'
      }
    ];
    return {
      loading: false,
      shown: false,
      form: {
        title: '',
        fileList: [],
        day: '',
        day_type: '',
        excel_url: '',
        dayStr: ''
      },
      uploading: false,
      rules: {
        title: [
          {
            required: true,
            whitespace: true,
            message: '请填写名单名称',
            trigger: 'blur'
          },
          {
            validator: titleValidator,
            message: '该名称已存在',
            trigger: 'blur'
          }
        ],
        fileList: [{ validator: fileListValidator, trigger: 'change' }],
        day: [{ validator: dayValidator, trigger: 'change' }]
      },
      options,
      optionType: 'week',
      defaultDate: [],
      upLoadPerson: [],
      dataFileList: [],
      fileOptions: ['请选择文件'],
      dataSourse: [],
      visible: false,
      columns,
      data: [],
      week: [
        moment().startOf('week').format('YYYY-MM-DD'),
        moment().endOf('week').format('YYYY-MM-DD')
      ],
      month: moment().startOf('month').format('YYYY-MM'),
      isupLoadFile: false,
      max: moment()
        .subtract(-1, 'week')
        .startOf('week')
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      downloading: false
    };
  },
  mounted() {
    this.getData();
    this.getTemplateUrl();
  },
  methods: {
    getTemplateUrl() {
      if (!this.templateUrl) {
        this.$store.dispatch('StatisticsParticipation/getTemplateUrl');
      }
    },
    show() {
      this.shown = true;
      this.getTemplateUrl();
    },
    close() {
      this.shown = false;
      this.form = {
        title: '',
        fileList: []
      };
      this.$refs.formRef.resetFields();
      this.uploading = false;
    },
    save() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.upload();
        }
      });
    },
    async upload() {
      if (this.uploading) {
        return;
      }
      this.uploading = true;
      this.loading = true;
      const params = this.getFromData();
      console.log('params,', params);
      const data = await userimportFile(params).finally(() => {
        this.loading = false;
      });
      if (!(data && data.error_code === 0)) {
        this.$message.error(data?.message || '上传失败');
        if (data?.data?.error) {
          this.visible = true;
          this.data = data.data.error;
        }
        this.uploading = false;
        // this.close();
        return;
      }
      this.$message.success(data?.message || '保存成功');
      this.uploading = false;
      this.isupLoadFile = false;
      this.getData();
      this.close();
    },
    async getData() {
      const data = await getScreennew({
        day_type: this.optionType
      });
      if (!(data && data.error_code === 0)) {
        return;
      }
      this.defaultDate = [];
      data.data.forEach((s) => {
        if (this.optionType === 'week') {
          this.defaultDate.push(moment(s.day, 'YYYY-MM-DD').format('YYYY-wo'));
          this.dataSourse = data.data;
          this.onWeekChange({
            value: [this.week[0]]
          });
        }
        if (this.optionType === 'month') {
          this.defaultDate.push(moment(s.day, 'YYYY-MM').format('YYYY-MM'));
          this.dataSourse = data.data;
          this.onMonthChange({
            value: [this.month]
          });
        }
      });

      // this.defaultDate = data.data.map((s)=>s.);
    },
    async deleteDelnew(params) {
      const data = await deleteDelnew(params);
      if (!(data && data.error_code === 0)) {
        this.$message.error(`删除失败,${data.message}`);
        return Promise.resolve(false);
      }
      this.$message.success('删除成功');
      return Promise.resolve(true);
    },
    beforeUpload(file) {
      this.form.fileList = [file];
      this.form.title =
        this.form.title || (file?.name || '').replace(/\.xlsx$/g, '');
      console.log(this.form);
      this.isupLoadFile = true;
      this.$nextTick(() => this.$refs.formRef.validate());
      return false;
    },
    // 自定义上传
    async handleRequestUpload(options = {}) {
      const { file, onError } = options;
      console.log(options);
      if (this.uploading) {
        onError('Uploading', 'Uploading');
        return;
      }
      this.uploading = true;
      this.form = { ...this.form, fileList: [file] };
      // onSuccess(params);
      this.$store.dispatch(
        'StatisticsParticipation/getParticipationPeopleList'
      );
      this.uploading = false;
    },
    onSelect() {
      this.getData();
    },
    onMonthChange(date) {
      console.log(
        'onMonthChange',
        date,
        moment(date.value).startOf('month').format('YYYY-MM-DD')
      );
      const data = this.dataSourse.find(
        (s) =>
          moment(s.day).format('YYYY-MM') ===
          moment(date.value).format('YYYY-MM')
      );
      if (data) {
        this.form.title = data.title;
        this.form.fileList = [data.excel_url];
        this.form.excel_url = data.excel_url;
      } else {
        this.form.title = '';
        this.form.fileList = [];
        this.form.excel_url = '';
      }
      this.form.day = date;
      this.form.dayStr = moment(date.value).startOf().format('YYYY-MM-DD');
      console.log(this.form);
    },
    onWeekChange(date) {
      console.log(
        date.value[0],
        moment(date.value[0]).startOf('week').format('YYYY-MM-DD')
      );
      const data = this.dataSourse.find(
        (s) =>
          moment(s.day).format('YYYY-wo') ===
          moment(date.value[0]).format('YYYY-wo')
      );
      console.log(data);
      if (data) {
        this.form.title = data.title;
        this.form.fileList = [data.excel_url];
        this.form.excel_url = data.excel_url;
      } else {
        this.form.title = '';
        this.form.fileList = [];
        this.form.excel_url = '';
      }
      this.form.day = date.value[0];
      this.form.dayStr = moment(date.value[0])
        .startOf('week')
        .format('YYYY-MM-DD');
    },
    async downloadFile() {
      const data = {};
      this.downloading = true;
      if (this.form.excel_url) {
        data.data = await downloadFileExtra({
          url: this.form.excel_url
        }).finally(() => {
          this.downloading = false;
        });
      } else {
        console.log(this.form.fileList);
        data.data = await downloadFileExtra({
          url: this.form.fileList[0]
        }).finally(() => {
          this.downloading = false;
        });
      }

      if (data) {
        this.$message.success('下载成功');
        return;
      }
      this.$message.error('下载失败');
    },
    async deleteFile() {
      if (this.isupLoadFile) {
        this.form.fileList = [];
        this.isupLoadFile = false;
        return;
      }
      const data = await this.deleteDelnew({
        day_type: this.optionType,
        day: this.form.dayStr
      });
      if (data) {
        this.getData();
        this.form.fileList = [];
      }
    },
    cacel() {
      window.location.reload();
    },
    getFromData() {
      console.log(
        'getFromData',
        this.form,
        moment(this.form.dayStr, this.day_type).startOf().format('YYYY-MM-DD')
      );
      const formData = new FormData();
      formData.append('file', this.form.fileList[0]);
      formData.append('template_title', this.form.title);
      formData.append('day_type', this.optionType);
      formData.append('day', this.form.dayStr);
      return formData;
    },
    onClose() {
      this.visible = false;
    },
    markFunction(e) {
      if (e.type === 'month') {
        if (
          this.defaultDate.includes(
            moment(
              `${e.year}-${e.month >= 10 ? e.month : `0${e.month}`}`
            ).format('YYYY-MM')
          )
        ) {
          return '<span class="flag" title="已选中">*</span>';
        }
      }
      if (e.type === 'date') {
        const date = `${e.year}-${e.month >= 10 ? e.month : `0${e.month}`}-${
          e.date >= 10 ? e.date : `0${e.date}`
        }`;
        if (this.defaultDate.includes(moment(date).format('YYYY-wo'))) {
          return '<span class="flag" title="已选中">*</span>';
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.people-import {
  width: 930px;
  height: calc(100% - 20px);
  background: #ffffff;
  display: block;
  padding: 50px;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
}
.flex {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  .btn-item {
    flex: 1;
    padding: 10px;
  }
}
.mr15 {
  margin-right: 15px;
}
.date-render {
  background: rgb(230, 247, 255);
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  justify-content: center;
  align-content: center;
}
.input-file {
  width: 180px;
  height: 35px;
  padding: 0 12px;
  margin: 0 10px 10px 0;
}
</style>
<style  lang="scss">
.flag {
  float: right;
  text-align: center;
  color: #0dc50d;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  &:hover {
    color: #ffffff;
    background-color: rgba(20, 189, 20, 0.85);
  }
}

.calendar {
  border: 1px solid #cccccc;
}
</style>
