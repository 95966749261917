import { render, staticRenderFns } from "./SelectShopType.vue?vue&type=template&id=123b7197&scoped=true&"
import script from "./SelectShopType.vue?vue&type=script&lang=js&"
export * from "./SelectShopType.vue?vue&type=script&lang=js&"
import style0 from "./SelectShopType.vue?vue&type=style&index=0&id=123b7197&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123b7197",
  null
  
)

export default component.exports